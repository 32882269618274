<template>
  <section class="space-y-10">
    <quick-info />
    <asom-alert
      class="mb-4"
      v-if="error"
      variant="error"
      :error-message="error"
    />
    <div class="w-full text-center" v-else-if="isLoading">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <div v-else class="print-content space-y-4">
      <h3 class="subheader">
        General Info
      </h3>
      <asom-card class="no_page_break">
        <asom-grid :sm="1" :md="2">
          <asom-form-field label="Reference No">
            <p class="mt-0 font-semibold">{{get(form, 'referenceNo')}}</p>
          </asom-form-field>
          <asom-form-field label="Requestor">
            <p class="font-semibold">{{get(form, 'requestorStation')}}</p>
          </asom-form-field>
          <asom-form-field label="Date">
            <p class="mt-0 font-semibold">{{displayUtcDateTime(get(form, 'dateCreated'))}}</p>
          </asom-form-field>
        </asom-grid>
      </asom-card>
      <template v-if="!skipIncoming">
        <br/>
        <h3 class="subheader">
          Incoming Items
        </h3>
        <asom-card class="no_page_break">
          <div class="space-y-2">
            <asom-form-field label="Duffle Bag Serial No">
              <p class="mt-0 font-semibold">
                <span v-if="!get(form, 'isDuffleBagRejected')">{{get(form, 'duffleBagSerialNo')}}</span>
                <span v-else class="space-x-2">
                  <span class="line-through">>{{get(form, 'duffleBagSerialNo')}}</span>
                  <asom-badge variant="error">Rejected</asom-badge>
                </span>
              </p>
            </asom-form-field>
            <asom-form-field label="Rejection Remarks" v-if="get(form, 'isDuffleBagRejected')">
              <p class="mt-0 font-semibold">{{get(form, 'duffleBagRejectionRemarks')}}
              </p>
            </asom-form-field>
            <template v-for="order in cashOrderList" :key="order.id">
              <div class="pl-4">
                  <asom-grid :sm="1" :lg="3" >
                    <asom-form-field label="Cash Order"  >
                      <p class="mt-0 font-semibold">{{order.referenceNo}}</p>
                    </asom-form-field>
                    <asom-form-field label="Order Amount">
                      <p class="mt-0 font-semibold">${{order.expectedAmount}}</p>
                    </asom-form-field>
                    <asom-form-field label="Received Amount">
                      <p class="mt-0 font-semibold">${{order.amount}}</p>
                    </asom-form-field>
                  </asom-grid>
                <asom-form-field v-if="hasIncomingCashBags(order)" label="" style="margin-top: 10px;">
                  <asom-client-table
                    :data="get(order, 'cashBags', [])"
                    :columns="[ 'no', 'bagNo', 'isRejected', 'remarks' ]"
                    :filterable="false"
                    :searchable="false"
                    :pagination="false"
                  >
                    <template v-slot:no="{rowIndex}">{{rowIndex + 1}}</template>
                    <template v-slot:isRejected="{data}">{{data ? 'Yes' : 'No'}}</template>
                  </asom-client-table>
                </asom-form-field>
                <denomination
                  v-if="get(order, 'receivedCoinTube')"
                  label="Received Coin Tubes"
                  readonly
                  show-remarks
                  :noOf10CentTubes="get(order, 'receivedCoinTube.noOf10CentTubes', 0)"
                  :noOf20CentTubes="get(order, 'receivedCoinTube.noOf20CentTubes', 0)"
                  :noOf50CentTubes="get(order, 'receivedCoinTube.noOf50CentTubes', 0)"
                  :noOf1DollarTubes="get(order, 'receivedCoinTube.noOf1DollarTubes', 0)"
                  :remarks="get(order, 'receivedCoinTube.remarks')"
                />
                <denomination
                  v-if="get(order, 'rejectedCoinTube')"
                  label="Received Coin Tubes"
                  readonly
                  show-remarks
                  :noOf10CentTubes="get(order, 'rejectedCoinTube.noOf10CentTubes', 0)"
                  :noOf20CentTubes="get(order, 'rejectedCoinTube.noOf20CentTubes', 0)"
                  :noOf50CentTubes="get(order, 'rejectedCoinTube.noOf50CentTubes', 0)"
                  :noOf1DollarTubes="get(order, 'rejectedCoinTube.noOf1DollarTubes', 0)"
                  :remarks="get(order, 'rejectedCoinTube.remarks')"
                />
              </div>
            </template>
          </div>
          
        </asom-card>  
      </template>
      <br />
      <h3 class="subheader">
        Outgoing Items
      </h3>
      <asom-card class="no_page_break">
        <asom-form-field 
          label="Outgoing TE Bags"
        >
          <asom-client-table
            v-if="outgoingCashBags.length > 0"
            :data="outgoingCashBags"
            :columns="[ 'no', 'bagNo', 'amount', 'remarks' ]"
            :filterable="false"
            :searchable="false"
            :pagination="false"
          >
            <template v-slot:no="{rowIndex}">{{rowIndex + 1}}</template>
            <template v-slot:amount="{data}">{{formatCurrency(data)}}</template>
          </asom-client-table>
          <p v-else class="pt-2">Nil bag returned</p>
        </asom-form-field>
        <asom-form-field 
          v-if="outgoingCashBoxes.length > 0"
          label="Outgoing Cash Boxes"
        >
          <asom-client-table
            v-if="outgoingCashBoxes"
            :data="outgoingCashBoxes"
            :columns="[ 'no', 'cashBoxNo', 'status', 'bagNo', 'remarks' ]"
            :filterable="false"
            :searchable="false"
            :pagination="false"
          >
            <template v-slot:no="{rowIndex}">{{rowIndex + 1}}</template>
            <template v-slot:amount="{data}">{{formatCurrency(data)}}</template>
          </asom-client-table>
        </asom-form-field>
        <asom-form-field 
          v-if="outgoingNonCashItems.length > 0"
          label="Outgoing Non Cash Items"
        >
          <asom-client-table
            v-if="outgoingNonCashItems"
            :data="outgoingNonCashItems"
            :columns="[ 'no', 'serialNumber', 'description', 'remarks' ]"
            :filterable="false"
            :searchable="false"
            :pagination="false"
          >
            <template v-slot:no="{rowIndex}">{{rowIndex + 1}}</template>
          </asom-client-table>
        </asom-form-field>
        <asom-form-field
          v-if="outgoingNonCashItems.length > 0 && attachments.length > 0"
          label="Supporting Documents"
        >
          <asom-upload-file-list :files="attachments" disabled-remove />
        </asom-form-field>
      </asom-card>
      <asom-form-field
        v-if="cashDeliveryAttachments.length > 0"
        label="Supporting Documents"
      >
        <asom-upload-file-list :files="cashDeliveryAttachments" disabled-remove />
      </asom-form-field>
      <br/>
      <h3 class="subheader">
        Confirmation
      </h3>
      <asom-card class="no_page_break" id="signature-container">
        <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-2 no-print">
          <asom-form-field label="Staff (SMRT)">
            <p class="font-semibold">{{get(form, 'officerName')}}</p>
            <asom-signature-view :file-id="get(form, 'smrtSignatureId')" />
          </asom-form-field>
          <asom-form-field label="Contractor">
            <p class="font-semibold">{{get(form, 'contractorName')}}</p>
            <asom-signature-view :file-id="get(form, 'contractorSignatureId')" />
          </asom-form-field>
        </div>
        <!-- Signature Layout for Print Preview -->
        <div class="flex justify-between mx-5 my-5 print-only">
          <asom-form-field label="Staff (SMRT)">
            <p class="font-semibold">{{get(form, 'officerName')}}</p>
            <div class="signature-grid">
              <asom-signature-view :file-id="get(form, 'smrtSignatureId')" />
            </div>
          </asom-form-field>
          <asom-form-field label="Contractor" class="signature-grid">
            <p class="font-semibold">{{get(form, 'contractorName')}}</p>
            <div class="signature-grid">
              <asom-signature-view :file-id="get(form, 'contractorSignatureId')" />
            </div>
          </asom-form-field>
        </div>
        <div class="col-span-2 flex justify-end space-x-4 no-print">
          <asom-button
            text="Back"
            variant="secondary"
            @click="$router.push({ name: 'SCCF Main' })"
          />
          <asom-button
            text="Print"
            icon="printer"
            variant="primary"
            @click="printCDF"
          />
        </div>
      </asom-card>
    </div>
  </section>
</template>
<script>
import get from 'lodash.get'
import isEmpty from 'lodash.isempty'
import { displayUtcDateTime } from '@/helpers/dateTimeHelpers'
import { formatCurrency } from "@/helpers/numbers";
import { getSCCFDetails } from '../../../../services/cashManagement.service';
import QuickInfo from "../../_QuickInfo.vue";
import Denomination from '../../_Denominations.vue'

export default {
  components: {
    QuickInfo,
    Denomination
  },
  data() {
    return {
      error: null,
      isLoading: false,
      form: null
    }
  },
  mounted() {
    this.loadData();
  },
  computed: {
    cashOrderList() {
      return get(this.form, 'cashOrderList', []);
    },
    skipIncoming() {
      return isEmpty(get(this.form, 'duffleBagSerialNo'));
    },
    outgoingCashBags() {
      return get(this.form, 'outgoingCashBags', [])
    },
    outgoingCashBoxes() {
      return get(this.form, 'outgoingCashBoxes', [])
    },
    outgoingNonCashItems() {
      return get(this.form, 'outgoingNonCashItems', [])
    },
    attachments() {
      return get(this.form, 'attachments', [])
    },
    cashDeliveryAttachments() {
      return get(this.form, 'cashDeliveryAttachments', [])
    },
    hasOutgoingItems() {
      return !isEmpty(this.outgoingCashBags) || !isEmpty(this.outgoingCashBoxes) || !isEmpty(this.outgoingNonCashItems);
    }
  },
  methods: {
    get,
    displayUtcDateTime,
    formatCurrency,
    async loadData() {
      this.error = null;
      this.isLoading = true;
      const id = get(this.$route, 'query.id');
      const result = await getSCCFDetails(id);
      if (result.success) {
        this.form = get(result, 'payload.data', {});
      } else {
        this.error = result.payload;
      }
      this.isLoading = false;
    },
    printCDF() {
      this.$scrollTopInstant();
      window.print();
    },
    hasIncomingCashBags({ cashBags }) {
      return !isEmpty(cashBags);
    }
  }
}
</script>
<style scoped>
  .no_page_break {
    page-break-after: avoid;
  }

  .signature-grid {
    max-width: 200px;
  }

  .print-only {
    display: none;
  }

  @media print {
    .no_page_break {
      width: 100%;
      overflow: visible;
      display: inline-table;
    }

    .print-only {
      display: flex;
    }
    #signature-container {
      width: 100%;
      display: block;
      overflow:visible;
      height:30rem;
      break-inside: avoid;
      page-break-inside: avoid;
    }
    @page {
      padding-bottom: 2rem;
      size: A3;
      margin: 0;
    }
  }
</style>